<template>
  <div class="page" id="index">
    <el-row :span="24" style="padding-top: 30px;width: 1200px">
      <el-col :span="18" class="index-left">
        <base-left-img @getclick="getclickimg"></base-left-img>
      </el-col>
      <el-col :span="6" class="index-right">
        <el-col :span="24"  v-if="is_click">
          <el-col class="index-right-top">
            <el-col class="right-title-name">{{ indexClickData.gh.name }}</el-col>
            <hr>
            <el-col class="right-connent-size" style="margin-top: 20px">大棚状态</el-col>
            <el-col class="right-size">正常</el-col>
          </el-col>
          <el-col class="index-right-img">
          </el-col>
          <el-col class="index-right-center">
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/type1.png" alt="" width="36"
                                                             height="36">
              </el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">种植作物</el-col>
                <el-col class="center-box-size-con">{{ indexClickData.gh.crop }}</el-col>
              </el-col>
            </el-col>
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/type2.png" alt="" width="36"
                                                             height="36"></el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">种植面积</el-col>
                <el-col class="center-box-size-con">{{ indexClickData.gh.area }}㎡</el-col>
              </el-col>
            </el-col>
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/type3.png" alt="" width="36" height="36">
              </el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">管理员</el-col>
                <el-col class="center-box-size-con">{{ indexClickData.gh.admin }}</el-col>
              </el-col>
            </el-col>
          </el-col>
          <el-col class="index-right-bottom">
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/status1.png" alt="" width="36" height="36">
              </el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">空气温度</el-col>
                <el-col class="center-box-size-con2">{{ indexClickData.data.temper }}℃</el-col>
              </el-col>
            </el-col>
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/status2.png" alt="" width="36" height="36"></el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">土壤湿度</el-col>
                <el-col class="center-box-size-con2">{{ indexClickData.data.landHumid }}%</el-col>
              </el-col>
            </el-col>
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/status3.png" alt="" width="36"
                                                             height="36"></el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">二氧化碳</el-col>
                <el-col class="center-box-size-con2">{{ indexClickData.data.co2 }}pph</el-col>
              </el-col>
            </el-col>
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/status4.png" alt="" width="36"
                                                             height="36"></el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">光照度</el-col>
                <el-col class="center-box-size-con2">{{ indexClickData.data.light }}klux</el-col>
              </el-col>
            </el-col>
          </el-col>
          <el-col style="text-align: center">
            <el-button @click="toDeatilGreenHpuse">管理</el-button>
          </el-col>
        </el-col>
        <el-col  :span="24"  v-else>
          <el-col class="index-right-top2">
            <el-col class="right-title-name">{{ indexData.name }}</el-col>
            <hr>
            <el-col style="font-size: 18px;color: #333333;margin-top: 9px">基地简介</el-col>
            <el-col style="margin-top: 6px;font-size: 14px;text-align: left;padding: 0 5px;overflow-y:auto;" v-html="indexData.desc">
<!--              {{ indexData.desc }}-->
            </el-col>
          </el-col>
          <el-col class="index-right-img">
            <el-carousel height="175px">
              <el-carousel-item v-for="item in imgList" :key="item">
                <img :src="item" alt="" width="300" height="172">
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col class="index-right-type">
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/gtype1.png" alt="" width="36"
                                                             height="36">
              </el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">大棚数量</el-col>
                <el-col class="center-box-size-con">{{ indexData.gnum }}个</el-col>
              </el-col>
            </el-col>
            <el-col :span="12" class="right-center-box">
              <el-col :span="12" class="center-box-img"><img src="../../assets/index/gtype2.png" alt="" width="36"
                                                             height="36"></el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">联网设备</el-col>
                <el-col class="center-box-size-con">{{ indexData.enum }}个</el-col>
              </el-col>
            </el-col>
            <el-col :span="20" class="right-center-box">
              <el-col :span="7" class="center-box-img"><img src="../../assets/index/gtype3.png" alt="" width="36"
                                                            height="36">
              </el-col>
              <el-col :span="17">
                <el-col class="center-box-size-title">种植作物</el-col>
                <el-col class="center-box-size-con">{{ indexData.crops }}</el-col>
              </el-col>
            </el-col>
            <el-col :span="13" class="right-center-box">
              <el-col :span="11" class="center-box-img"><img src="../../assets/index/gtype4.png" alt="" width="36"
                                                             height="36"></el-col>
              <el-col :span="12">
                <el-col class="center-box-size-title">种植面积</el-col>
                <el-col class="center-box-size-con">{{ indexData.areas }}㎡</el-col>
              </el-col>
            </el-col>
          </el-col>
          <el-col class="index-right-message">
            <el-col class="right-message-title">农技信息</el-col>
            <el-col class="right-message-con">
              <el-col class="right-message-row">
                <span>山东省秋粮收购政策调整</span>
              </el-col>
              <el-col class="right-message-row">
                <span>山东省秋粮收购政策调整</span>
              </el-col>
              <el-col class="right-message-row">
                <span>山东省秋粮收购政策调整</span>
              </el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import baseLeftImg from "@/components/baseLeftImg";
export default {
  components: {
    baseLeftImg,
  },
  data() {
    return {
      msg: '',
      is_click: '',
      indexData: {},
      imgList:[],
      indexClickData: {},
      clickGreenHouse:''
    }
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('user_nongye'))){
      this.$api.post('base/getBase', {}, res => {
        console.log(res)
        this.indexData = res.data
        if (res.data.img2){
          this.imgList =   JSON.parse(res.data.img2)
        }
        localStorage.setItem('basename', res.data.name)
      })
    }else {
      this.$router.push('/login')
    }

  },
  methods: {
    getclickimg(data) {
      console.log(data, 'zizujian')
      this.clickGreenHouse = data
      let params = {
        id: data
      }
      this.$api.post('gHouse/getById', params, res => {
        console.log(res, '大棚信息返回')
        if (res.code === 0) {
          this.indexClickData = res
          this.is_click = true;
        }

      })
    },
    toDeatilGreenHpuse(){
      this.$router.push({
        name: "CanopyManagement",
        query: {
          id: this.clickGreenHouse
        }
      })
    }
  }
}
</script>
<style>
.index-right-message::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.index-left {
  height: 880px;
}

.index-right {

}

.index-right-top {
  text-align: center;
  background: #FFFFFF;
}

.index-right-top2 {
  min-height: 230px;
  text-align: center;
  background: #FFFFFF;
  overflow: hidden;
}

.right-connent-size {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

#index .el-button {
  width: 250px;
  height: 40px;
  background: #009201;
  border-radius: 20px;
  color: #FFFFFF;
  margin-top: 20px;
}

.right-title-name {
  font-size: 20px;
  color: #009400;
  margin: 20px 0;

}

.right-size {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #009400;
  margin-bottom: 29px;
  margin-top: 14px;
}

.index-right-img {
  margin: 20px 0;
  height: 172px;
}

.index-right-center {
  padding-top: 5px;
  height: 136px;
  background: #FFFFFF;
}

.index-right-bottom {
  height: 140px;
  background: #FFFFFF;
  margin-top: 20px;
  padding-top: 5px;
}

.right-center-box {
  height: 68px;
  /*line-height: 68px;*/
}

.center-box-size-title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
}


.center-box-size-con {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 3px;
}

.center-box-size-con2 {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0090FF;
  margin-top: 4px;

}

#index .center-box-img {
  line-height: 88px;
  text-align: center;
  vertical-align: middle;

}


/*===*/
.index-right-type {
  height: 200px;
  background: #FFFFFF;
}

.index-right-message {
  height: 120px;
  margin-top: 22px;
  background: #FFFFFF;
  text-align: center;
  overflow: auto;
}

.right-message-title {
  height: 40px;
  background: #009400;
  line-height: 40px;
  color: #FEFEFE;
  font-size: 18px;
  border-radius: 8px 8px 0px 0px;
}

.right-message-row {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f1f0f0;
}

.right-message-con {
  height: 80px;

}
</style>
