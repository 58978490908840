<template>
  <div>
    <div style="z-index: 10" class="imgName">
      <div style="visibility: hidden">1</div>
<div>
  <div class="left-air-box">
    <!--        <el-col :span="12" style="height: 70px">-->
    <!--          <el-col :span="9" :offset="2" style="text-align: center;line-height: 90px"><img-->
    <!--              src="../assets/index/left1.png" alt="">-->
    <!--          </el-col>-->
    <!--          <el-col :span="13">-->
    <!--            <el-col class="left-box-title">今日天气</el-col>-->
    <!--            <el-col class="left-box-connent">多云</el-col>-->
    <!--          </el-col>-->
    <!--        </el-col>-->

    <!--        <el-col :span="12" style="height: 70px">-->
    <!--          <el-col :span="9" :offset="2" style="text-align: center;line-height: 90px"><img-->
    <!--              src="../assets/index/left2.png" alt="">-->
    <!--          </el-col>-->
    <!--          <el-col :span="13">-->
    <!--            <el-col class="left-box-title">温度</el-col>-->
    <!--            <el-col class="left-box-connent">-2-34℃</el-col>-->
    <!--          </el-col>-->
    <!--        </el-col>-->

    <!--        <el-col :span="12" style="height: 70px">-->
    <!--          <el-col :span="9" :offset="2" style="text-align: center;line-height: 90px"><img-->
    <!--              src="../assets/index/left3.png" alt="">-->
    <!--          </el-col>-->
    <!--          <el-col :span="13">-->
    <!--            <el-col class="left-box-title">湿度</el-col>-->
    <!--            <el-col class="left-box-connent">100%</el-col>-->
    <!--          </el-col>-->
    <!--        </el-col>-->

    <!--        <el-col :span="12" style="height: 70px">-->
    <!--          <el-col :span="9" :offset="2" style="text-align: center;line-height: 90px"><img-->
    <!--              src="../assets/index/left4.png" alt="">-->
    <!--          </el-col>-->
    <!--          <el-col :span="13">-->
    <!--            <el-col class="left-box-title">风力</el-col>-->
    <!--            <el-col class="left-box-connent">南风8级</el-col>-->
    <!--          </el-col>-->
    <!--        </el-col>-->

  </div>
  <div style="position: absolute;top:30px;left: 35px;z-index: 100">
    <iframe width="250" height="60" frameborder="0" scrolling="no" hspace="0" src="https://i.tianqi.com/?c=code&a=getcode&id=94&icon=1&site=16"></iframe>
  </div>
</div>
      <el-tooltip class="item" effect="dark" content="大棚" placement="top">
        <div class="left1-img" @click="getclickImg('1')">
          <img src="../assets/baseImg/1.png" alt="">
        </div>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="大棚" placement="top">
        <div class="left1-img2" @click="getclickImg('2')">
          <img src="../assets/baseImg/4.png" alt="">
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "baseLeftImg",
  methods: {
    // getclickImg(data) {
    //   this.$emit('getclick', data)
    // },
    enter() {

    },
    leave() {

    }
  }
}
</script>

<style scoped>
.imgName {
  background-image: url("../assets/baseImg/base.png");
  height: 616px;
  position: relative;
  width: 880px;
}

.left1-img {
  position: absolute;
  top: 150px;
  left: 170px;
  cursor: pointer;
}

.left1-img2 {
  position: absolute;
  top: 330px;
  left: 430px;
  cursor: pointer;
}

/*.left-air-box {*/
/*  width: 280px;*/
/*  height: 142px;*/
/*  margin-left: 20px;*/
/*  background: #FEFEFE;*/
/*  z-index: 100;*/
/*  opacity: 0.7;*/
/*  box-shadow: 0 0 79px 0 rgba(0, 0, 0, 0.27);*/

/*}*/
.left-air-box {
  width: 240px;
  height: 62px;
  position: relative;
  margin-left: 20px;
  padding-left: 10px;
  padding-top: 10px;
  background: #FEFEFE;
  z-index: 100;
  border-radius: 10px;
  opacity: 0.7;
  /*box-shadow: 0 0 79px 0 rgba(0, 0, 0, 0.27);*/

}

.left-box-title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-top: 15px;
}

.left-box-connent {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 3px;
}
</style>
